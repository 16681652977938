$desk: 1024px;
$mb: 1023px;

.wrapper {
  min-height: 100vh;
  overflow: hidden;
}


*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
  line-height: normal;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #000;
  font-weight: 300;
  font-family: 'Raleway' !important;
  font-size: 1.8rem;
  line-height: 2.6rem;
  background-color: #fff;

  @media screen and (max-width: $mb){
    font-size: 1.3rem;
    line-height: 2rem;
  }
}

html {
  font-size: 0.520833vw;
}

.container {
  padding-left: 7.2rem;
  padding-right: 7.2rem;
  max-width: 100%;
  width: 100%;

  @media screen and (max-width: $mb){
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.sc__title {
  font-size: 15rem;
  font-weight: 700;

  @media screen and (max-width: $mb){
    font-size: 5rem;
  }
}

.sc__promo {
  .container {
    padding-top: 18rem;
    padding-bottom: 18rem;

    @media screen and (max-width: $mb){
      padding-top: 14.5rem;
      padding-bottom: 7.2rem;
    }
  }

  .sc__ct {
    padding-left: 17.6rem;

    @media screen and (max-width: $mb){
      padding-left: 2rem;
    }
  }

  .sc__title {
    line-height: 10.5rem;

    @media screen and (max-width: $mb){
      line-height: 3.6rem;
    }
  }
}

.anim__box {
  position: relative;
  height: 16.4rem;
  margin-top: 4.2rem;
  margin-bottom: 3.3rem;

  @media screen and (max-width: $mb){
    height: 7.2rem;
    margin-top: 3.4rem;
    margin-bottom: 2.4rem;
  }

  &:before{
    width: 16.4rem;
    top: 1rem;
    left: -3.6rem;
    position: absolute;
    bottom: -1rem;
    background: #70F9BB;
    content: '';
    border-radius: 50%;

    @media screen and (max-width: $mb){
      width: 7.2rem;
      left: -2rem;
      top: 0.35rem;
      bottom: -0.35rem;
    }
  }
}

.anim__it {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  &:not(.active){
    display: none !important;
  }

  .sc__title {
    color: #fff;
    white-space: nowrap;
    position: relative;
    z-index: 2;
  }
}

.anim__it-bg {
  position: absolute;
  left: -3.6rem;
  right: -3.6rem;
  top: 1rem;
  bottom: -1rem;
  border-radius: 8.2rem;
  background: #70F9BB;

  @media screen and (max-width: $mb){
    left: -2.2rem;
    right: -2.2rem;
    border-radius: 3.6rem;
    top: 0.35rem;
    bottom: -0.35rem;
  }
}

.sc__showreel {
  height: 100vh;
  overflow: hidden;
  width: 100% !important;

  @media screen and (max-width: $mb){
    height: auto;
  }

  .container {
    position: relative;
    height: 100%;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}

.video {
  &__box {
    position: relative;
    height: 100%;
    margin-right: -3.2rem;
    margin-left: -3.2rem;

    @media screen and (max-width: $mb){
      margin-left: 0;
      margin-right: 0;
      height: 20.2rem;
    }

    .sc__title {
      color: #fff;
      line-height: 11.8rem;

      @media screen and (max-width: $mb){
        line-height: 3.6rem;
        margin-top: 1.1rem;
      }
    }

    @media screen and (min-width: $desk){
      .sc__header {
        position: absolute;
        top: 6.9rem;
        left: 33.7rem;
        z-index: 5;
        will-change: top, left;
        transform: translateX(-50%) translateY(-80%);
      }
    }

    &-img {
      border-radius: 2.5rem;
      overflow: hidden;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 2.5rem;
        background: linear-gradient(72deg, rgba(0, 0, 0, 0.35) 51.23%, rgba(112, 249, 187, 0.27) 97.4%);
      }

      picture, img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-ct {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__icon {
    width: 8.8rem;
    height: 8.8rem;
    position: absolute;
    left: 14.8rem;
    bottom: -5.9rem;

    @media screen and (max-width: $mb){
      position: relative;
      width: 6.4rem;
      left: auto;
      margin-left: auto;
      margin-right: auto;
      height: 6.4rem;
      bottom: auto;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  }
}

.sc__subtitle {
  color: #000;
  font-size: 3.8rem;
  font-weight: 600;
  line-height: 5.8rem;

  @media screen and (max-width: $mb){
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

.sc__about {
  .container {
    padding-bottom: 30rem;
    padding-top: 14.8rem;

    @media screen and (max-width: $mb){
      padding-bottom: 5rem;
      padding-top: 5rem;
    }
  }

  .sc__ct {
    max-width: 106.8rem;
  }

  @media screen and (max-width: $mb){
    .sc__subtitle {
      br {
        display: none;
      }

      margin-right: -0.6rem;
    }
  }
}

.sc__lbl {
  margin-bottom: 6.7rem;
  border: 1px solid #000;
  height: 4.8rem;
  padding-right: 3.2rem;
  padding-left: 3.2rem;
  color: #000;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  border-radius: 2.4rem;

  @media screen and (max-width: $mb){
    height: 4.4rem;
    border-radius: 2.2rem;
    margin-bottom: 3.6rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    font-size: 1.2rem;
  }
}

.sc__advants {
  background-color: #1F1F21;

  .container {
    padding-top: 23.6rem;
    padding-bottom: 30rem;

    @media screen and (max-width: $mb){
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  .sc__header {
    margin-bottom: 14rem;
    padding-left: 7.2rem;

    @media screen and (max-width: $mb){
      padding-left: 0;
      margin-bottom: 6.2rem;
    }
  }

  .sc__title {
    color: #fff;
    line-height: 20rem;

    @media screen and (max-width: $mb){
      line-height: 6.8rem;
    }
  }
}

.arrow__anim {
  width: 8.7rem;
  height: 8.7rem;
  position: absolute;
  z-index: 3;
  transform: translateY(28.1rem) translateX(-60rem);
  will-change: transform;
  transition: transform 0.5s ease;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.advants {
  &__list {
    max-width: 115.6rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &[data-active="0"]{
      .arrow__anim {
        transform: translateY(28.1rem) translateX(5rem);
      }
    }

    &[data-active="1"]{
      .arrow__anim {
        transform: translateY(65.3rem) translateX(5rem);
      }
    }

    &[data-active="2"]{
      .arrow__anim {
        transform: translateY(103.3rem) translateX(5rem);
      }
    }

    &[data-active="3"]{
      .arrow__anim {
        transform: translateY(141.3rem) translateX(5rem);
      }
    }

    &[data-active="4"]{
      .arrow__anim {
        transform: translateY(179.3rem) translateX(5rem);
      }
    }

    &[data-active="5"]{
      .arrow__anim {
        transform: translateY(217.3rem) translateX(5rem);
      }
    }
  }

  &__it {
    &--wrap {
      &:not(:last-child){
        margin-bottom: 3.8rem;

        @media screen and (max-width: $mb){
          margin-bottom: 3rem;
        }
      }

      &:not(.active){
        .advants__it {
          border-color: #79797A;
        }

        .advants__it-descr {
          color: #79797A;
        }

        .advants__it-icon {
          path {
            stroke: #79797A;
          }
        }
      }
    }

    height: 34.2rem;
    border-radius: 17.1rem;
    padding-left: 22rem;
    border: 0.25rem solid #70F9BB;
    display: flex;
    align-items: center;
    padding-right: 15rem;

    @media screen and (max-width: $mb){
      height: 15.5rem;
      border-radius: 7.75rem;
      border: 0.175rem solid #70F9BB;
      padding-left: 3rem;
      padding-right: 3.2rem;
    }

    &-icon {
      width: 12.1rem;
      min-width: 12.1rem;
      height: 9.2rem;
      margin-right: 9rem;

      @media screen and (max-width: $mb){
        width: 4.3rem;
        min-width: 4.3rem;
        height: 3.4rem;
        margin-right: 1.4rem;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        overflow: visible !important;
      }
    }

    &-descr {
      color: #FFF;
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 3.2rem;

      @media screen and (max-width: $mb){
        font-size: 1.5rem;
        line-height: 2rem;

        br {
          display: none !important;
        }
      }
    }
  }
}

.sc__projects {
  background: #F2F2F2;

  .container {
    padding-top: 30rem;
    padding-bottom: 30rem;

    @media screen and (max-width: $mb){
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  .sc__header {
    padding-left: 7.2rem;
    margin-bottom: 18rem;

    @media screen and (max-width: $mb){
      padding-left: 0;
      margin-bottom: 6.2rem;
    }
  }

  .sc__title {
    line-height: 20rem;

    @media screen and (max-width: $mb){
      line-height: 6.8rem;
    }
  }
}

.partners__list {
  max-width: 142rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: $desk){
    .keen-slider {
      display: grid !important;
      overflow: visible !important;
      grid-column-gap: 5.6rem;
      grid-row-gap: 5.6rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .row {
    margin-right: -2.8rem;
    margin-left: -2.8rem;
    margin-bottom: -5.6rem;
  }

  @media screen and (max-width: $mb){
    padding-right: 7rem;

    &-row {
      margin-right: -1.2rem;
      margin-left: -1.2rem;
    }

    .keen-slider {
      overflow: visible !important;

      &__slide {
        overflow: visible !important;

        padding-right: 1.2rem;
        padding-left: 1.2rem;
      }
    }
  }
}

.partners__it {
  /*padding-left: 2.8rem;
  padding-right: 2.8rem;
  margin-bottom: 5.6rem;*/

  @media screen and (min-width: $desk){
    min-width: 0 !important;
    max-width: none !important;
    overflow: visible !important;
  }
}

.partners__it-bl {
  height: 28rem;
  border-radius: 1.8rem;
  background: #FFF;
  box-shadow: 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: $mb){
    height: 18.2rem;
    border-radius: 1.4777rem;
    box-shadow: 0 0.656755rem 0.985133rem 0 rgba(0, 0, 0, 0.08);
  }

  &:hover {
    .partners__it-logo {
      opacity: 1;
    }
  }
}

.partners__it-logo {
  opacity: 0.25;
  will-change: opacity;
  transition: opacity 0.3s ease;

  @media screen and (max-width: $mb){
    opacity: 1;
    border-radius: 1.4777rem;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    filter: grayscale(100%);
  }
}

.part--1 {
  .partners__it-logo {
    width: 29rem;
  }
}

.part--2 {
  .partners__it-logo {
    width: 21.1rem;
  }
}

.sc__contacts {
  position: relative;
  background: #70F9BB;

  .container {
    min-height: 60rem;
    height: 100vh;
  }

  .sc__ct {
    width: 100%;
  }

  .row {
    margin-left: -2.8rem;
    margin-right: -2.8rem;
  }

  .col {
    padding-left: 2.8rem;
    padding-right: 2.8rem;

    @media screen and (max-width: $mb){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .sc__title {
    line-height: 21.8rem;

    @media screen and (max-width: $mb){
      line-height: 6.8rem;
      margin-bottom: 3.3rem;
    }
  }

  .sc__subtitle {
    font-size: 3.6rem;
    line-height: 6rem;
    margin-bottom: 5.2rem;

    @media screen and (max-width: $mb){
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 4.7rem;
    }
  }
}

.btn__arrow {
  height: 6.2rem;
  border-radius: 3.1rem;
  background: #FFF;
  box-shadow: 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.08);
  padding-left: 3rem;
  padding-right: 3rem;
  color: #000 !important;
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  will-change: background-color;
  transition: background-color 0.2s ease;

  @media screen and (max-width: $mb){
    height: 5.8rem;
    border-radius: 2.9rem;
    padding: 0 2.5rem;
    font-size: 1.6rem;
  }

  &:hover {
    background-color: #70F9BB;
  }

  &--filled {
    border: none;
    background-color: #70F9BB;
  }

  span {
    margin-right: 1.6rem;

    @media screen and (max-width: $mb){
      margin-right: 2rem;
    }
  }
}

.sc__contacts-email {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 3.2rem;
  color: #000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-align: center;

  @media screen and (max-width: $mb){
    bottom: 0;
    font-size: 1.5rem;
    padding: 1.6rem;
    text-align: left;
  }

  a {
    color: #000 !important;
  }
}

.icon__right {
  width: 9.1rem;
  height: 3.6rem;

  .icon__right {
    width: 8rem;
    height: 3.1rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sc__partners {
  .container {
    padding-top: 28.6rem;
    padding-bottom: 30rem;

    @media screen and (max-width: $mb){
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
  }

  .sc__header {
    margin-bottom: 18rem;

    @media screen and (max-width: $mb){
      margin-bottom: 7.4rem;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: $mb){
    height: 8.4rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FFF;
      box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.05);
      height: 100%;
      z-index: 3;
    }
  }

  .container {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;

    @media screen and (max-width: $mb){
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
    }
  }
}

.logo {
  width: 20rem;
  height: 7.1rem;

  @media screen and (max-width: $mb){
    width: 13.6rem;
    position: relative;
    z-index: 4;
  }

  svg {
    width: auto;
    height: 100%;
    display: block;
  }
}

.projects__data-sl-it {
  position: relative;
}

.dots {
  position: absolute;
  right: 4rem;
  top: 6.6rem;
  display: flex;
  z-index: 5;
}

.dot {
  cursor: pointer;
  background: #CCCCCC;
  border-radius: 0.8rem;
  width: 1.5rem;
  height: 1.5rem;

  &.active {
    background-color: #70F9BB;
  }

  &:not(:last-child){
    margin-right: 2.5rem;
  }
}

.nav__bars {
  width: 7rem;
  height: 7rem;
  border-radius: 3.5rem;
  background-color: #70F9BB;
  cursor: pointer;
  position: relative;
  z-index: 5;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.20);

  @media screen and (max-width: $mb){
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
  }
}

.icon__bars {
  width: 2.6rem;
  user-select: none;

  @media screen and (max-width: $mb){
    width: 1.8rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.nav__box {
  position: absolute;
  right: 0;
  top: 0;
  background: #FFF;
  box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.08);
  padding-left: 8rem;
  padding-right: 15rem;
  height: 100%;
  border-radius: 3.5rem;
  will-change: transform, opacity;
  opacity: 0;
  visibility: hidden;
  transform: translateX(1rem);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;

  @media screen and (max-width: $mb){
    height: calc(100vh - 6.4rem);
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    top: 6.4rem;
    width: 100vw;
    right: -1.6rem;
    background: #1F1F21;
    transform: translateX(100%);
  }
}

.nav__wrap {
  position: relative;
}

.nav__menu {
  height: 100%;
  user-select: none;

  ul {
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: $mb){
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      padding: 5.6rem 4rem;
    }

    li {
      color: #000;
      font-size: 1.8rem;
      font-weight: 700;
      white-space: nowrap;

      @media screen and (max-width: $mb){
        width: 100%;
        font-size: 4rem;
        line-height: 2.8rem;
      }

      a {
        color: #000 !important;
        cursor: pointer;
        will-change: color;
        transition: color 0.2s ease;

        @media screen and (max-width: $mb){
          color: #fff !important;
        }

        &:hover {
          color: #70F9BB !important;

          @media screen and (max-width: $mb){
            color: #fff !important;
          }

        }
      }

      &:not(:last-child){
        margin-right: 5.6rem;

        @media screen and (max-width: $mb){
          margin-right: 0;
          margin-bottom: 5.7rem;
        }
      }
    }
  }
}

.icon__close {
  width: 3.8rem;
  user-select: none;

  img {
    display: block;
    width: 100%;
  }
}

.header--nav {
  .nav__box {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}

.sc__title {
  user-select: none;
}

.sc__contacts-inner {
  .container {
    padding-top: 21.2rem;
    padding-bottom: 32.8rem;

    @media screen and (max-width: $mb){
      padding-top: 14.5rem;
      padding-bottom: 19.5rem;
    }
  }

  .sc__header {
    padding-left: 7.2rem;
    margin-bottom: 4.5rem;

    @media screen and (max-width: $mb){
      padding-left: 2rem;
      margin-bottom: 8rem;
    }
  }

  .sc__title {
    line-height: 20rem;

    @media screen and (max-width: $mb){
      line-height: 6.8rem;
    }
  }

  .anim__box {
    margin-bottom: 0;
  }
}

.page__redirect-box {
  position: fixed;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  right: 0;
  will-change: transform;
  transition: transform 0.75s ease;
  transform: translateX(100%);

  @media screen and (max-width: $mb){
    transform: translateY(-100%);
  }

  &.active {
    transform: translateX(0);

    @media screen and (max-width: $mb){
      transform: translateY(0);
    }
  }
}

.page__loader {
  background: #70F9BB;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  will-change: opacity, visibility;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
  }
}

.eye__circle-anim {
  will-change: transform;
  transition: transform 0.1s ease;
}

.page__loader-icon {
  width: 39.4rem;
  height: 39.4rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3.8rem;

  @media screen and (max-width: $mb){
    width: 16.4rem;
    height: 16.4rem;
    margin-bottom: 1.8rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.page__loader-progress {
  width: 39.6rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #FFF;
  height: 2rem;
  border-radius: 1rem;
  position: relative;

  @media screen and (max-width: $mb){
    width: 16.4rem;
    height: 0.8rem;
    border-radius: 0.4rem;
  }

  span {
    position: absolute;
    left: -1px;
    bottom: -1px;
    top: -1px;
    background: #000;
    border-radius: 1rem;

    @media screen and (max-width: $mb){
      border-radius: 0.4rem;
    }
  }
}

.video__frame {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  will-change: opacity;
  transition: opacity 0.3s ease;
  opacity: 0;

  &.loaded {
    opacity: 1;
  }

  & > div {
    width: 100% !important;
    height: 100% !important;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 2.5rem;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 2.5rem;
    background: linear-gradient(72deg, rgba(0, 0, 0, 0.35) 51.23%, rgba(112, 249, 187, 0.27) 97.4%);
  }
}

.form__before {
  margin-bottom: 11.5rem;

  @media screen and (max-width: $mb){
    margin-bottom: 8rem;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .sc__subtitle {
    line-height: 4.4rem;
    font-size: 3.6rem;

    @media screen and (max-width: $mb){
      line-height: 2.4rem;
      font-size: 1.6rem;
      max-width: 25.6rem;
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 7.8rem;
    }
  }
}

.logo {
  cursor: pointer;
}

.form__contacts {
  position: relative;
  margin-bottom: 12rem;

  @media screen and (max-width: $mb){
    & + .d-flex {
      justify-content: flex-start !important;
    }
  }
}

.form__anchor {
  height: 14rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;

  @media screen and (max-width: $mb){
    height: 8.4rem;
  }
}

.form__before-arrow {
  width: 2.5rem;
  height: 5.4rem;
  min-width: 2.5rem;
  margin-left: 35.8rem;
  position: relative;
  cursor: pointer;
  will-change: opacity;
  transition: 0.2s opacity ease;
  z-index: 5;

  @media screen and (max-width: $mb){
    margin-left: auto;
    margin-right: auto;
    width: 1.6rem;
    height: 3.8rem;
    min-width: 1.6rem;
  }

  &:hover {
    opacity: 0.5;
  }

  &:before {
    content: '';
    left: -1rem;
    right: -1rem;
    top: 0;
    bottom: 0;
    position: absolute;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.form__group {
  border-top: 1px solid #000;
  padding-top: 5rem;

  @media screen and (max-width: $mb) {
    padding-top: 2.6rem;
  }

  &-title {
    color: #000;
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 4.4rem;
    margin-bottom: 6.2rem;

    @media screen and (max-width: $mb) {
      font-size: 2.2rem;
      line-height: 2rem;
      margin-bottom: 4.6rem;
    }
  }
}

.form__list {
  .row {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
    margin-bottom: -2.8rem;

    @media screen and (max-width: $mb) {
      margin-bottom: -2.6rem;
    }
  }
}

.form__it {
  margin-bottom: 2.8rem;
  padding-right: 3.5rem;
  padding-left: 3.5rem;

  @media screen and (max-width: $mb) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2.6rem;
  }
}

.form__field {
  input, textarea {
    display: block;
    width: 100%;
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0 0.8rem 1.2rem rgba(0, 0, 0, 0.08);
    height: 8rem;
    border-radius: 4rem;
    padding: 0 3.5rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
    will-change: border-color;
    transition: border-color 0.2s ease;

    @media screen and (max-width: $mb) {
      height: 7rem;
      border-radius: 3.5rem;
      font-size: 1.6rem;
      padding: 0 2.4rem;
    }

    &:focus {
      border-color: #70F9BB;
    }

    &::-webkit-input-placeholder {
      color: #BBBBBC;
    }

    &::-ms-input-placeholder {
      color: #BBBBBC;
    }
  }

  &.has-error {
    input, textarea {
      border-color: #ff0000;
    }
  }

  textarea {
    height: 26.6rem;
    resize: none;
    padding-top: 3.2rem;

    @media screen and (max-width: $mb) {
      padding-top: 2.9rem;
    }
  }
}

.error__message {
  color: #ff0000;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 1.6rem;

  @media screen and (max-width: $mb) {
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }
}

.form__group {
  &:not(:last-child){
    margin-bottom: 12.5rem;

    @media screen and (max-width: $mb) {
      margin-bottom: 8rem;
    }
  }
}

.sc__contacts-inner {
  position: relative;

  .sc__contacts-email {
    background: #70F9BB;
    bottom: 0;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    @media screen and (max-width: $mb) {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      text-align: left;
      font-size: 1.5rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}

.radio__list {
  margin-right: -1.8rem;
  margin-bottom: -1.8rem;

  @media screen and (max-width: $mb){
    margin-right: -1.6rem;
    margin-bottom: -1.6rem;
  }
}

.radio__it {
  height: 5.4rem;
  padding: 0 3rem;
  box-shadow: 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.10);
  color: #000;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 1.8rem;
  margin-bottom: 1.8rem;
  border-radius: 2.7rem;
  cursor: pointer;
  border: 2px solid transparent;
  will-change: border-color, background-color;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  @media screen and (max-width: $mb){
    height: 4.8rem;
    border-radius: 2.4rem;
    padding: 0 1.4rem;
    margin-right: 0.8rem;
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }

  &:hover {
    border: 2px solid #70F9BB;
  }

  &.active {
    background-color: #70F9BB;
    color: #000 !important;
    border: 2px solid #70F9BB;
    cursor: default;
  }
}

.form__groups {
  margin-bottom: 20rem;

  @media screen and (max-width: $mb){
    margin-bottom:  9rem;
  }
}

.sc__projects-inner {
  position: relative;

  .container {
    padding-top: 21rem;

    @media screen and (max-width: $mb){
      padding-top: 14.5rem;

      .sc__header {
        margin-bottom: 10rem;
      }

      .form__before {
        margin-bottom: 6rem;
      }
    }
  }
}

@media screen and (min-width: $desk){
  .projects {
    height: 100vh;
    position: relative;
  }
}

@media screen and (min-width: 1024px){
  .sc__projects-list{
    .container {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}

.project {

  height: 100vh;
  padding: 2rem 0;

  @media screen and (min-width: $desk){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:not(.active){
      opacity: 0;
      visibility: hidden;
    }
  }

  @media screen and (max-width: $mb){
    padding: 0;
    max-height: 59.5rem;
    margin-right: 0;
    margin-left: 0;
  }

  &__bl {
    position: relative;
    border-radius: 1.8rem;
    overflow: hidden;
    height: 100%;

    @media screen and (max-width: $mb){
      border-radius: 1.6rem;
    }
  }
}

.project__img {
  border-radius: 1.8rem;
  overflow: hidden;
  height: 100%;

  img, picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.project {
  &.loaded {
    .project__video {
      opacity: 1;
      visibility: visible;
    }
  }
}

.project__video {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  will-change: opacity;
  transition: opacity 0.2s ease;
  opacity: 0;
  overflow: hidden;
  border-radius: 1.8rem;

  @media screen and (max-width: $mb){
    border-radius: 1.6rem;
  }

  & > div {
    width: 100% !important;
    height: 100% !important;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.project__ct {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  cursor: url("../img/cursor.svg"), auto;

  @media screen and (max-width: $mb){
    bottom: auto;
  }
}

.project {
  &.play--2 {
    .project__ct {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.project__hd {
  position: relative;
}

.project__arrow{
  position: absolute;
  width: 8.7rem;
  height: 8.7rem;
  bottom: 0;
  right: 13rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.project__subtitle {
  position: absolute;
  right: 6.2rem;
  bottom: 6.2rem;
  color: #FFF;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 600;
  max-width: 88rem;
  line-height: 3.7rem;

  @media screen and (max-width: $mb){
    right: auto;
    bottom: auto;
    font-size: 1.5rem;
    line-height: 2.2rem;
    position: relative;
    margin-right: -1rem;

    br {
      display: none;
    }
  }
}

.project__title {
  color: #FFF;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 700;
  user-select: none;
  line-height: 22.8rem;

  @media screen and (max-width: $mb){
    font-size: 2.6rem;
    line-height: 3.2rem;
    text-align: left;
    margin-bottom: 2.3rem;
  }
}

.project__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.60);
  border-radius: 1.8rem;

  @media screen and (max-width: $mb){
    border-radius: 1.6rem;
  }
}

.projects {
  position: relative;
  margin-bottom: 16.4rem;

  @media screen and (max-width: $mb){
    margin-bottom: 9rem;
  }

  &__anchor {
    height: 14rem;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;

    @media screen and (max-width: $mb){
      height: 8.4rem;
    }
  }
}

.project__tags {
  position: absolute;
  left: 6.2rem;
  bottom: 6.2rem;
  flex-wrap: wrap;

  @media screen and (max-width: $mb){
    left: 2.5rem;
    top: 2.5rem;
  }
}

.sc__subtitle {
  user-select: none;
}

.logo {
  path {
    will-change: fill;
    transition: fill 0.2s ease;
  }
}

.project__bl {
  display: block;
}

.project__tag {
  height: 4.2rem;
  border-radius: 2.1rem;
  padding: 0 2.3rem;
  background: #FFF;
  box-shadow: 0 0.8rem 1.2rem 0 rgba(0, 0, 0, 0.08);
  color: #000;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.8rem;

  @media screen and (max-width: $mb){
    height: 2.2rem;
    border-radius: 1.1rem;
    padding: 0 1.2rem;
    font-size: 0.659rem;
    box-shadow: 0 0.405517rem 0.608276rem 0 rgba(0, 0, 0, 0.08);
  }

  &:not(:last-child){
    margin-right: 0.9rem;

    @media screen and (max-width: $mb){
      margin-right: 0.5rem;
    }
  }
}

.project__hd {
  position: absolute;

  @media screen and (max-width: $mb){
    position: relative;
    width: 100%;
  }
}

.video__box {
  overflow: hidden;
}

@media screen and (min-width: $desk){
  /*.project--1 {
    .project__hd {
      right: 12rem;
      bottom: 24rem;
    }
  }

  .project--2 {
    .project__hd {
      right: 12rem;
      bottom: 24rem;
    }
  }*/
}

.sc__projects{
  .sc__action {
    margin-top: 9.5rem;

    @media screen and (max-width: $mb){
      margin-top: 4rem;
    }
  }
}

.projects__sl-box {
  height: 110.2rem;
  position: relative;

  @media screen and (max-width: $mb){
    height: auto;
  }
}

.projects__sl-it {
  height: 110.2rem;
}

.projects__data-sl {
  position: absolute;
  height: 100%;
  left: 17.7rem;
  top: 0;
  display: flex;
  align-items: center;
  width: 58rem;
  z-index: 7;
}

.projects__data-sl-it {
  border-radius: 1.8rem;
  background: #FFF;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.02);
  padding: 5.6rem;
  width: 100%;

  .project__tags {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 5.6rem;
  }
}

.projects__data-sl-it-logo {
  height: 5.5rem;
  margin-bottom: 6rem;

  img {
    height: 100%;
    display: block;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.projects__media-sl {
  position: absolute;
  right: 0;
  width: 130.6rem;
  height: 100%;
  top: 0;
}

.projects__media-sl-it {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border-radius: 1.8rem 0 0 1.8rem;
  will-change: transform;
  transition: transform 0.5s ease;
  transform: translateX(100%);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.60);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.3s ease;
  }

  &.active {
    transform: translateX(0);
  }

  &.current {
    z-index: 3;

    &:hover {
      &:after {
        opacity: 1;
      }

      .projects__media-sl-it-ct {
        opacity: 1;
      }
    }
  }
}

.projects__media-sl-it-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    width: 100% !important;
    height: 100% !important;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.projects__media-sl-it-ct {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding-left: 21.5rem;
  will-change: opacity;
  transition:  opacity 0.3s ease;
  opacity: 0;
  cursor: url("../img/cursor.svg"), auto;
}

.projects__data-sl-it-title {
  color: #000;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 4.1rem;
  line-height: 1;
}

.projects__media-sl-it-title {
  color: #FFF;
  text-align: center;
  font-size: 16rem;
  font-weight: 700;
  line-height: 20.8rem;
}

.projects__data-sl-it-subtitle {
  margin-bottom: 4.4rem;
  color: #000;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.projects__next-link {
  color: #CCC;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;

  &:not(:last-child){
    margin-bottom: 5.8rem;
  }

  a {
    color: #CCC !important;
    cursor: pointer;
    will-change: color;
    transition: color 0.2s ease;

    &:hover {
      color: #000 !important;
    }
  }
}

.projects__sl-box {
  margin-right: -7.2rem;

  @media screen and (max-width: $mb){
    margin-right: 0;
    padding-right: 6.4rem;
  }
}

.projects__media-sl-it-img {
  position: absolute;
  right: -47.1rem;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 1.8rem;

  picture, img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.projects__data-sl-it {
  &:not(.active){
    display: none !important;
  }
}

.project__over {
  border-radius: 1.8rem;
  background: #70F9BB;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
}

.sc__projects-after {
  position: relative;

  .sc__contacts-email {
    background: #70F9BB;
    bottom: 0;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }

  .container {
    padding-bottom: 30rem;

    @media screen and (max-width: $mb){
      padding-bottom: 15rem;

      & > .d-flex {
        justify-content: flex-start !important;
      }
    }
  }
}

.showreel__logo {
  height: 15rem;

  @media screen and (max-width: $mb){
    height: 5rem;
    margin-top: 1rem;
  }

  img {
    display: block;
    width: auto;
    height: 100%;
  }
}

@media screen and (min-width: $desk){
  .mb-only {
    display: none !important;
  }
}

@media screen and (max-width: $mb){
  .desk-only {
    display: none !important;
  }

  html {
    font-size: 2.5641vw;
  }

  .proj {
    &__it {
      padding-right: 1.2rem;
      padding-left: 1.2rem;

      &-main {
        position: relative;
        border-radius: 1.6rem 1.6rem 0 0;
        overflow: hidden;
        margin-bottom: -1.2rem;
      }

      &-logo {
        height: 2.8rem;
        margin-bottom: 3rem;

        img {
          display: block;
          height: 100%;
          width: auto;
        }
      }

      &-title {
        color: #000;
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
        margin-bottom: 2rem;
      }

      &-descr {
        color: #000;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.8rem;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }

      &-video {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        & > div {
          width: 100% !important;
          height: 100% !important;

          video {
            width: 100% !important;
            height: 100% !important;
            display: block;
            object-fit: cover;
          }
        }
      }

      &-img {
        height: 27.5rem;
        position: relative;

        picture, img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.40);
        }
      }

      &-ct {
        background: #fff;
        border-radius: 1.6rem;
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.02);
        padding: 2.5rem 2.5rem 0;
        height: 22.6rem;
        position: relative;
        z-index: 5;
      }
    }
  }

  .proj__sl-row {
    margin-right: -1.2rem;
    margin-left: -1.2rem;

    .keen-slider:not([data-keen-slider-disabled]) {
      overflow: visible;
    }
  }

  .partners__list {
    position: relative;
  }

  .sl__nav {
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    background-color: #70F9BB;
    z-index: 4;
    top: 50%;
    margin-top: -2.4rem;
    right: -0.3rem;
    cursor: pointer;

    svg {
      width: 2.8rem;
      height: 1.9rem;
      display: block;
    }
  }

  .sc__projects-list {
    .project__tags {
      position: relative;
      left: 0;
      bottom: 0;
      justify-content: flex-start;
    }

    .project__ct {
      flex-wrap: wrap;
      padding: 3rem;
      justify-content: flex-start !important;
      height: 100%;
      align-items: flex-start;
      align-content: flex-start;
    }

    .project__tag {
      height: 2.8rem;
      padding: 0 1.4rem;
      border-radius: 1.4rem;
      font-size: 1rem;

      &:not(:last-child){
        margin-right: 0.8rem;
      }
    }
  }

  .project__logo {
    height: 3.4rem;
    margin-bottom: 3rem;

    img {
      width: auto;
      height: 100%;
      display: block;
    }
  }

  .project {
    &:not(:last-child){
      margin-bottom: 3rem;
    }
  }

  .project__action {
    position: absolute;
    bottom: 2.5rem;
    left: 3rem;
  }

  .sc__action {
    justify-content: flex-start !important;
  }
}