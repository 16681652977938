$color: #ffffff;
$mb: 1023px;
$desk: 1024px;
$baseFontFamily: 'Apercu Pro';
$textFontFamily: 'Apercu Pro';

.modal__close {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  cursor: pointer;
  top: 2.4rem;
  right: 2.4rem;
  opacity: 1;
  z-index: 5;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;


  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: $mb){

  }

  &:hover {
    opacity: 0.7;
  }
}

.modal{
  &__backdrop {
    position: fixed;
    background: rgba(#000, 0.7);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 400;
  }

  &__content {
    position: fixed;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;

    &-box {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      position: relative;

      @media screen and (max-width: $mb){
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-area {
      min-height: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal__form-text {
  color: #FFE291;
  text-align: center;
  font-family: $baseFontFamily;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;

  @media screen and (max-width: $mb){
    font-size: 1.4rem;
  }

  a {
    color: #FFE291 !important;
    transition: opacity 0.2s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}

.btn--full {
  width: 100%;
}

.modal__form {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $mb){
    width: 100%;
  }
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}

.modal__content {
  overflow-x: hidden;
}

.modal__content-box {
  margin: 0 !important;
  background-color: #FFFFFF;
  width: 100%;
}

.modal__checkbox-it {
  &:not(:last-child){
    margin-bottom: 3rem;

    @media screen and (max-width: $mb){
      margin-bottom: 1.4rem;
    }
  }
}

.modal__icon {
  background: $color;
  width: 6rem;
  height: 6rem;
  font-size: 3.2rem;
  border-radius: 50%;
  font-weight: 700;
  color: #fff;
  margin: 0 auto 2.4rem;
}

.modal__subtitle {
  color: #FFF;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin-bottom: 0.8rem;

  a {
    color: #FFE291 !important;
    border-bottom: 1px solid rgba(#FFE291, 0.5);
    transition: border-color 0.2s ease;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  @media screen and (max-width: $mb){

  }
}


.social__links {
  a{
    width: 4rem;
    height: 4rem;
    background-color: #fff;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;

    @media screen and (max-width: $mb){

    }

    &:not(:last-child){
      margin-right: 1rem;

      @media screen and (max-width: $mb){

      }
    }

    &:hover {
      background-color: rgba(#fff, 0.8);
    }

    &:active {
      transform: scale(0.95);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.modal__ct {
  position: relative;
  z-index: 3;
}

.modal__form-submit{
  width: 100%;
}

.form--loading {
  position: relative;

  .modal__form-it,
  .modal__actions,
  .modal__checkboxes,
  .form__list,
  .form__action,
  .search__field,
  .search__submit,
  .modal__social{
    opacity: 0.3;
  }

  &:before{
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    position: absolute;
  }

  &:after {
    content: '';
    //background: url("../img/loader.svg") no-repeat center;
    background-size: contain;
    width: 9.6rem;
    height: 9.6rem;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -4.8rem;
    margin-top: -4.8rem;
  }
}

.modal__content-area {
  display: flex;
}

.modal__content-box {
  max-width: 110rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  background: #616264;
  text-align: left;
  color: #F2F2F2;

  &-bl {
    position: relative;
    padding: 4.8rem 6rem;
  }

  @media screen and (max-width: $mb){
    margin: 0;
  }
}

.modal--content {
  .modal__backdrop {
    background: rgba(255, 255, 255, 0.75);
  }
}

.modal--info {
  .modal__backdrop {
    background: rgba(255, 255, 255, 0.75);
  }

  .modal__close {
    right: 0.4rem;
    top: 0.4rem;

    path {
      fill: #6A6A6A;
    }
  }

  .modal__content-box {
    max-width: 38rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    border: 1px solid #6A6A6A;
    background: #DBDBDB;
    text-align: center;
    color: #000;

    &-bl {
      position: relative;
      padding: 2.4rem 6rem;
    }

    @media screen and (max-width: $mb){
      margin: 0;
    }
  }
}

.modal__title {
  margin-bottom: 2.4rem;
  color: #FFF;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;

  &--large {
    font-size: 2.8rem;
    text-transform: uppercase;
  }
}

.modal--rules {
  .modal__title {
    margin-bottom: 2.5rem;
  }
}

.modal__subtitle {
  text-align: left;
  text-transform: uppercase;
  span {
    color: #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    font-size: 1.3rem;
  }
  margin-bottom: 2.4rem;
}

.modal__txt  {
  h2, h3, h4, h5, h6 {
    margin-bottom: 2.4rem;
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  p {
    color: #F2F2F2;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 1.6rem 0;
  }

  a {
    color: #F2F2F2 !important;
    border-bottom: 1px solid #F2F2F2;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.modal__content-area {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: $mb){
    padding-top: 0;
    padding-bottom: 0;
  }
}


.faq__box {
  max-width: 67.5rem;
  margin-left: auto;
  margin-right: 0;
}

.modal__bl {
  &:not(:last-child){
    margin-bottom: 2rem;
  }
}

.modal--docs {
  .modal__content-box {
    max-width: 64rem;
  }
}

.modal {
  .sc__title {
    color: #fff;
    margin-bottom: 2.4rem;
  }

  .docs__it-title {
    color: #fff;

    a {
      color: #fff !important;
    }
  }

  .docs__it-download {
    color: #fff;

    a {
      color: #fff !important;
      border-bottom: 1px solid #fff;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.modal--frame {
  font-size: 0;
  .modal__content-box {
    max-width: 110rem;

    &-bl {
      position: relative;
      padding: 4rem 4rem 0;
    }
  }
}

.modal__frame {
  height: 70rem;
  margin-left: -4rem;
  margin-right: -4rem;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.modal__title--large {
  font-size: 2.8rem;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
}

.modal--sms:not(.modal--info) {
  .modal__content-box {
    max-width: 37.2rem;
    border: 1px solid #6A6A6A;
    background: #DBDBDB;
    color: #000;
  }

  .modal__close {
    display: none !important;
  }

  .modal__backdrop {
    background: rgba(255, 255, 255, 0.75);
  }

  .modal__content-box-bl {
    padding: 1.6rem;
  }

  .error__message {
    font-size: 1.2rem;
  }
}

.code__form-title {
  color: #6A6A6A;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.code__form-field {
  input {
    border: 1px solid #2F3338;
    height: 3.2rem;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #000;
    width: 100%;
    display: block;
    background: transparent;
  }

  position: relative;

  &.has-error {
    input {
      border-color: #A44F29;
    }
  }
}

.code__form-hint {
  position: absolute;
  right: 1.6rem;
  text-align: right;
  top: 1rem;
  font-size: 1rem;
  line-height: 1.6rem;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  color: #6A6A6A;

  a {
    color: #A44F29 !important;
    cursor: pointer;
  }
}

.code__form-descr {
  color: #000;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 1rem;
}

@media screen and (max-width: $mb){

}